import React from 'react'

const Footer = () => {
  return (
    <footer className='z-50 w-screen p-3 px-4 md:p-6 md:px-16 bg-secondary'>
      <div className='container text-center'>
        Footer
      </div>

    </footer>
  )
}

export default Footer